import React, { useEffect } from 'react';
import logo from './logo.svg';
import './App.css';

function App() {
  useEffect(() => {
    // Redirect to the specified URL
    window.location.href = 'http://menu.lewan-restobar.com/';
  }, []); // The empty array ensures this effect runs only once after the initial render

  return (
    <div className="App">
      {/* Content here will not be visible as the page will redirect immediately */}
    </div>
  );
}

export default App;
